// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-concurso-js": () => import("./../../../src/pages/concurso.js" /* webpackChunkName: "component---src-pages-concurso-js" */),
  "component---src-pages-contato-js": () => import("./../../../src/pages/contato.js" /* webpackChunkName: "component---src-pages-contato-js" */),
  "component---src-pages-contato-sucesso-js": () => import("./../../../src/pages/contato-sucesso.js" /* webpackChunkName: "component---src-pages-contato-sucesso-js" */),
  "component---src-pages-evento-js": () => import("./../../../src/pages/evento.js" /* webpackChunkName: "component---src-pages-evento-js" */),
  "component---src-pages-historico-js": () => import("./../../../src/pages/historico.js" /* webpackChunkName: "component---src-pages-historico-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-sobre-js": () => import("./../../../src/pages/sobre.js" /* webpackChunkName: "component---src-pages-sobre-js" */)
}

